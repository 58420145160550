export const COTTAGE_EMAILS = Object.freeze({
  BOT: 'bot@cotta.ge',
  INTERNAL: 'internal@cotta.ge',
  INVOICING: 'invoicing@cotta.ge',
  MARKETPLACE: 'marketplace@cotta.ge',
  GROWTH_LEAD: 'kyu@cotta.ge',
  MARKETPLACE_ASSOCIATE: 'cole@cotta.ge',
});

// Precomputed set for fast lookup during filtering
export const COTTAGE_EMAILS_SET: Set<string> = new Set(
  Object.values(COTTAGE_EMAILS)
);

export const ALREADY_VERIFIED = 'User already verified';
